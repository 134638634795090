import React from 'react'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Header from '../components/Header'

const NotFoundPage = () => (
    <Layout>
        <Header>
            <span className="underline">Oops!</span> I can't seem to find the
            page you're looking for.
        </Header>
        <Section
            title="REDIRECT"
            subtitle="You can try these existing pages instead"
        >
            <div className="content">
                <ul>
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/writing">Writing</a>
                    </li>
                </ul>
            </div>
        </Section>
    </Layout>
)

export default NotFoundPage
